import { List, Map } from 'immutable';
import { select } from 'redux-saga/effects';
import Cookie from 'universal-cookie';
import { getImmutableUtmCookie } from '@dbh/app-cookies';
import { getGdprConsentMap } from '@dbh/cookie-consent-utils';
import { selectNavigationHistoryState } from '@dbh/navigation-history-redux';
import 'prop-types';
import 'react-immutable-proptypes';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import '@dbh/generic-types';

/**
 * Given the dictionary of `UTM` query parameters taken directly from the `URL`,
 * it extracts the value of the parameter with the given name (`key`).
 * @param {Immutable.Map} utmValues .
 * @param {string} key Query parameter name in the `URL`. Get the value, if valid.
 * @return {string?} Value of the query parameter.
 */const extractUtmValue=withConformsTo("extractUtmValue",[],(a,b)=>{let c=a.get(b);// It is technically possible, even though unlikely, that in the `URL`
// one or more `utm_*` parameters have multiple values assigned to them.
// It is an explicit business requirement to pick the **last** one.
// It is also technically possible, even though unlikely, that the value is
// a number and not a string, in that case we ignore it.
return List.isList(c)&&!c.isEmpty()&&(c=c.last()),"string"==typeof c?c:void 0}),createAttributionData=withConformsTo("createAttributionData",[],a=>{if(!Map.isMap(a))return;const b=extractUtmValue(a,"utm_source"),c=extractUtmValue(a,"utm_campaign"),d=extractUtmValue(a,"utm_medium"),e=extractUtmValue(a,"utm_term"),f=extractUtmValue(a,"utm_content"),g=extractUtmValue(a,"utm_id"),h=a.get("lpOfSessionInWhichUtmCookieWasSet"),i=a.get("lpOfSessionInWhichUtmCookieWasSetPageType"),j=a.get("utmCookieCreationDate"),k=a.get("utmCookieExpirationDate"),l=a.get("utmCookieVersion"),m=a.get("utmCookieSetBy");return b||d||c||e||f||h||i||g||l||m?Map({source:b,medium:d,campaign:c,term:e,content:f,id:g,lpOfSessionInWhichUtmCookieWasSet:h,lpOfSessionInWhichUtmCookieWasSetPageType:i,utmCookieCreationDate:j,utmCookieExpirationDate:k,utmCookieVersion:l,utmCookieSetBy:m}).filter(Boolean):void 0});/**
 * Given the dictionary of `UTM` query parameters taken directly from the `URL`
 * or from our `UTM` cookie, it returns a subset, to be sent to the `bookings`
 * `API`, and other back end `APIs`, for tracking purposes.
 * @param {Immutable.Map?} utmValues .
 * @return {Immutable.Map?} `undefined` if the input is not valid.
 */var createAttributionData$1 = createAttributionData;

function*getTrackingDataForBackEnd(a){const b=a||new Cookie,c=getImmutableUtmCookie(b),d=getGdprConsentMap(b),e=d.get("performance"),f=null==d?void 0:d.get("targeting"),g=e?yield select(selectNavigationHistoryState):void 0,h=f?createAttributionData$1(c):void 0,i=Map({gdprConsent:d,attribution:f&&!(null!=h&&h.isEmpty())&&h,navigationHistory:e&&!(null!=g&&g.isEmpty())&&g}).filter(Boolean);return i}

const trackingDataForBackEndImmutablePropType=[];var trackingDataForBackEndImmutablePropType$1 = trackingDataForBackEndImmutablePropType;

export { createAttributionData$1 as createAttributionData, getTrackingDataForBackEnd, trackingDataForBackEndImmutablePropType$1 as trackingDataForBackEndImmutablePropType };
