import '@babel/runtime/helpers/esm/taggedTemplateLiteralLoose';
import { combineReducers } from '@reduxjs/toolkit';
import 'common-tags';
import 'prop-types';
import { reducer } from 'redux-form/immutable';
import { REDUCER_INJECTED } from '@dbh/redux-extra';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$4, developmentReducer } from '@dbh/development-redux';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$7, GlobalStylesReducer } from '@dbh/global-styles';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$2, languageProviderReducer } from '@dbh/language-provider-redux';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$3, dateFnsLocalesReducer } from '@dbh/date-fns-locales-redux';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$5 } from '@dbh/redux-form';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$1, routeInternalsReducer } from '@dbh/redux-route-internals';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$9, cancellableReduxSagaSpawnReducer } from '@dbh/cancellable-redux-saga-spawn';
import { REDUX_AND_SAGA_KEY, routeReducer } from '@dbh/routing-redux';
import { SSR_DATA_REDUX_AND_SAGA_KEY, ssrDataReducer } from '@dbh/ssr-data-redux';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$8, daybreakHeroReducer } from '@dbh/daybreak-hero-redux';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$6, trackingScriptsManagerReducer } from '@dbh/tracking-scripts-redux';
import { REDUX_AND_SAGA_KEY as REDUX_AND_SAGA_KEY$a, universalCookieReducer } from '@dbh/universal-cookie';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';

/**
 *
 * `ReducerManagerError`: `@dbh/configure-redux-store`.
 *
 */class ReducerManagerError extends Error{constructor(a){super(a),this.name=ReducerManagerError.getClassName();}}ReducerManagerError.getClassName=()=>"ReducerManagerError";var ReducerManagerError$1 = ReducerManagerError;

/**
 * Creates a reducer manager that handles dynamic reducer injection.
 * @see {@link https://redux.js.org/usage/code-splitting}
 * @see {@link https://redux.js.org/usage/structuring-reducers/initializing-state#recap}
 * @param {Object} initialReducers Initial set of reducers to start with.
 * @return {Object} Reducer manager methods to manage reducers.
 */const createReducerManager=a=>{// Create an object which maps keys to reducers.
const b={// Needed by `react-router`.
[REDUX_AND_SAGA_KEY]:routeReducer,[REDUX_AND_SAGA_KEY$1]:routeInternalsReducer,[REDUX_AND_SAGA_KEY$2]:languageProviderReducer,[REDUX_AND_SAGA_KEY$3]:dateFnsLocalesReducer,[REDUX_AND_SAGA_KEY$4]:developmentReducer,// @see {@link https://redux-form.com/8.2.2/examples/immutable/}
[REDUX_AND_SAGA_KEY$5]:reducer,[REDUX_AND_SAGA_KEY$6]:trackingScriptsManagerReducer,[SSR_DATA_REDUX_AND_SAGA_KEY]:ssrDataReducer,[REDUX_AND_SAGA_KEY$7]:GlobalStylesReducer,[REDUX_AND_SAGA_KEY$8]:daybreakHeroReducer,[REDUX_AND_SAGA_KEY$9]:cancellableReduxSagaSpawnReducer,[REDUX_AND_SAGA_KEY$a]:universalCookieReducer,// Do NOT add any initial reducers here for injected reducers, otherwise
// in PRODUCTION (result of "yarn build" and then "yarn start:prod")
// everything breaks because the initial state passed to reducers
// is the empty one defined here. Instead we want the initial state for
// each injected reducer to be te one defined on top of the reducers
// themselves: this way the selectors work as expected and there are no
// fatal exceptions related to `.get` or `.getIn` expressions.
...a};let c=combineReducers(b),d=[];// An array which is used to delete state keys when reducers are removed.
return {getReducerMap:()=>b,// The root reducer function exposed by this object.
// This will be passed to the store.
reduce:(a,b)=>{// When a reducer is injected at runtime, `Redux`'s `preloadedState` doesn't
// include its initial state. If we have an initialState value for this
// reducer's key, we need to manually restore it here.
// @see {@link https://redux.js.org/usage/structuring-reducers/initializing-state#recap}
if(b.type===REDUCER_INJECTED){const{key:d,initialState:e}=b.payload;const f={...a,...(e?{[d]:e}:{})};return c(f,b)}// If any reducers have been removed, clean up their state first.
if(0<d.length){const e={...a};for(const a of d)delete e[a];return d=[],c(e,b)}return c(a,b)},// Adds a new reducer with the specified key.
add:withConformsTo("add",[],(a,d)=>{// Add the reducer to the reducer mapping.
// Generate a new combined reducer.
b[a]=d,c=combineReducers(b);}),// Removes a reducer with the specified key.
remove:a=>{a&&b[a]&&(// Remove it from the reducer mapping.
// Add the key to the list of keys to clean up.
// Generate a new combined reducer.
delete b[a],d.push(a),c=combineReducers(b));}}};var createReducerManager$1 = createReducerManager;

export { ReducerManagerError$1 as ReducerManagerError, createReducerManager$1 as createReducerManager };
