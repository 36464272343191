/*
 *
 * Helper: `adaptHydratedState`.
 *
 */
import PropTypes from 'prop-types';
import withConformsTo from '@dbh/with-conforms-to';
import { fromJS } from 'immutable';

// Temporary hardcoded list. We don't want to add more imports here. To avoid
// importing more packages, we'll just add keys here. And we'll remove this
// once we have a better way to hydrate the state. Or completely remove
// `immutableJS` from the project.
export const NOT_IMMUTABLEJS_REDUCERS = [
  'GlobalStyles',
  'DisableWindowScroll',
  'TopMenu',
  'Menu',
  'WelcomeBanner',
  'AddToCartCards',
  'ChangelogPage',
  'ContainerMapPopup',
];

// We remove `dateFnsLocales` reducer because it contains non-serializable state,
// which are functions. They are loaded on the client side any way. Which makes
// sense because we can't serialize functions.
export const REMOVE_REDUCERS = ['dateFnsLocales'];

/**
 * This is to avoid the following error or breaks the redux selectors until
 * the fully migration to `@reduxjs/toolkit` is done:
 *
 * `The previous state received by the reducer has unexpected type of "Map".
 * Expected argument to be an object with the following keys: "dataChangelog",
 * "infoChangelog" Error Component Stack`.
 */
export const IMMUTABLEJS_SUB_REDUCERS = [
  'UserArea',
  'shoppingcart',
  'InvoicesPayment',
  'MessagesPanelPage',
];

const convertToImmutable = (state) =>
  Object.keys(state).reduce(
    (acc, key) => ({
      ...acc,
      [key]: fromJS(state[key]),
    }),
    {},
  );

// @TODO: Remove this once we have a better way to hydrate the state.
const adaptHydratedState = withConformsTo(
  'adaptHydratedState',
  IS_PRODUCTION_WWW ? [] : ['state', PropTypes.object],
  (state) => {
    const hydratedState = Object.keys(state).reduce((acc, key) => {
      const shouldConvertToImmutable = !NOT_IMMUTABLEJS_REDUCERS.includes(key);
      const shouldConvertSubReducersToImmutable = IMMUTABLEJS_SUB_REDUCERS.includes(
        key,
      );
      const shouldRemoveReducer = REMOVE_REDUCERS.includes(key);

      if (shouldRemoveReducer) {
        return {
          ...acc,
          [key]: {},
        };
      }

      if (shouldConvertSubReducersToImmutable) {
        return {
          ...acc,
          [key]: convertToImmutable(state[key]),
        };
      }

      if (shouldConvertToImmutable) {
        return {
          ...acc,
          [key]: fromJS(state[key]),
        };
      }

      return {
        ...acc,
        [key]: state[key],
      };
    }, {});

    return hydratedState;
  },
);

export default adaptHydratedState;
